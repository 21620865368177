<template>
  
      <v-card
        class="mx-auto my-12"
        max-width="374">

        <v-img
          height="180"
          src="@/assets/images/banner.jpg">
        </v-img>

        <v-card-text v-if="!loading">
          
          <v-row>
            <v-col align="center" cols="12">
              {{$i18n.t('NovaSenha')}}
            </v-col>
          </v-row>
          <br>

          <v-form
            ref="form"
            v-model="valido"
            lazy-validation>
            <v-text-field
              ref="login"
              v-model="login"
              :rules="loginRules"
              :label="$i18n.t('Login')"
              required>
            </v-text-field>
          </v-form>
        
        </v-card-text>

        <div class="text-center mg-10" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary">
          </v-progress-circular>
        </div>

        <v-card-actions v-if="!loading">

          <div class="w100">
            <v-row>
              <v-col align="center" cols="12">
                <v-btn
                  color="primary"
                  elevation="2"
                  @click="envia"
                  :disabled="!validateForm">
                  {{$i18n.t('Enviar')}}
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col align="left" lg="6" cols="6" sm="6">
                <div
                  class="link-home"
                  @click="voltaLogin">
                  {{$i18n.t('VoltaParaLogin')}}
                </div>
              </v-col>

              <v-col align="right" cols="6" lg="6" sm="6">
                <div
                  class="link-home"
                  @click="acessaSemCredencial">
                  {{$i18n.t('SemCredencial')}}
                </div>
              </v-col>
            </v-row>
          </div>
          
        </v-card-actions>
        
      </v-card>
  
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      valido: false,
      login: '',
      loginRules: [
        v => !!v
      ]
    }),
    computed: {
        validateForm() {
          return this.valido && this.login != '';
        },
    },
    methods: {
      envia() {
        if (this.validateForm) {
            this.loading = true;
            this.$http.post('/login/recupera', 
              {
                "host": document.location.origin + '/#/login/confirma',
                "login": this.login
              })
              .then((res) => {
                if (res.data.sucesso)
                  this.$msg.sucesso(res.data.mensagem);
                else
                  this.$msg.erro(res.data.mensagem);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.$refs.login.focus();
          }
      },
      acessaSemCredencial() {
        this.$router.push('/rastrear');
      },
      voltaLogin() {
        this.$router.push('/');
      }
    },
    mounted() {
      this.$refs.login.focus();
    }
  }
</script>